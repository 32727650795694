<template>
  <div>
    <p class="text-2xl mb-6">Créer un nouveau Rapport</p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="reportForm">
            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="report.title"
                  outlined
                  dense
                  :rules="[required]"
                  label="Titre du rapport"
                  disabled
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="report.siteGroup"
                  :items="siteGroupList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Univers"
                  dense
                  :rules="[valueSelected]"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="report.email"
                  outlined
                  dense
                  :rules="[emailValidator, required]"
                  label="Email"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveReport"
                >
                  Enregistrer le Rapport</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'admin-list-email-report' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {
  emailValidator,
  required,
  valueSelected,
} from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";

export default {
  name: "EmailReportCreateOrUpdate",
  async created() {
    this.siteGroupList = await this.$store.dispatch("common/getSiteGroupList");
  },
  data() {
    return {
      required,
      valueSelected,
      siteGroupList: [],
      report: {
        title: null,
        siteGroup: null,
        email: null,
      },
    };
  },
  methods: {
    emailValidator,
    async saveReport() {
      const isFormValid = this.$refs.reportForm.validate();
      if (!isFormValid) return;
      const payload = {
        title: this.report.title,
        group_sites_id: this.report.siteGroup,
        email: this.report.email,
      };

      const { status } = await axios.post("/email-reports/", payload);
      if (status === 200) {
        await router.push({ name: "admin-list-email-report" });
      }
    },
    normalize(s) {
      return s
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-z0-9 -]/gi, "")
        .replace(/[\s.*]/gi, "");
    },
    setTitle() {
      const siteGroup = this.siteGroupList.filter(
        (siteGroup) => siteGroup.id === this.report.siteGroup
      );
      this.report.title = `Rapport daily ${siteGroup[0].name}`;
    },
  },
  watch: {
    "report.siteGroup": function () {
      this.setTitle();
    },
  },
};
</script>

<style scoped></style>
